<template>
  <div class="wrapper-field">
    <v-form ref="form" class="fields-password" @submit.prevent="changePassword">
      <div class="edit-information">
        <h1>Change password</h1>
        Create a new password
      </div>
      <div class="edit-password-profile">
        <v-text-field
          label="Current Password"
          :type="hideOldPassword ? 'password' : 'text'"
          v-model="form.oldPassword"
          outlined
          requried
          :append-icon="hideOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="hideOldPassword = !hideOldPassword"
        >
          <template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-lock.svg"
              alt=""
            /> </template
        ></v-text-field>
        <v-text-field
          label="New Password"
          :type="hideNewPassword ? 'password' : 'text'"
          v-model="form.newPassword"
          :rules="passwordRules"
          outlined
          requried
          :append-icon="hideNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @input="newPasswordInput"
          @click:append="hideNewPassword = !hideNewPassword"
          ><template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-lock.svg"
              alt=""
            /> </template
        ></v-text-field>
        <v-text-field
          ref="confirmPassword"
          label="Repeat new password"
          :type="hideConfirmPassword ? 'password' : 'text'"
          v-model="form.confirmPassword"
          :rules="passwordConfirmRules"
          outlined
          requried
          :append-icon="hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="hideConfirmPassword = !hideConfirmPassword"
          ><template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-lock.svg"
              alt=""
            /> </template
        ></v-text-field>
        <v-btn
          class="profile-password-btn"
          :disabled="!submitEnable()"
          width="100%"
          type="submit"
          color="primary"
          :loading="isUpdating"
        >
          Change Password
        </v-btn>
      </div>
      <loader-component v-if="isUpdating"></loader-component>
      <snackbar-component
        v-if="notification.length > 0"
        :show="notification.length > 0"
        :text="notification"
        :color="notificationColor"
        @closeNotify="closeNotify"
      >
      </snackbar-component>
    </v-form>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";

import LoaderComponent from "@/components/OverlayComponent";
import { mapActions } from "vuex";

export default {
  components: {
    LoaderComponent,
  },
  data() {
    return {
      form: {},
      hideOldPassword: true,
      hideNewPassword: true,
      hideConfirmPassword: true,
      isUpdating: false,
      notification: "",
      notificationColor: "",
      passwordRules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 6) || "Min 6 characters",
        (value) =>
          value !== this.form.oldPassword || "You entered your old password",
      ],
      passwordConfirmRules: [
        (value) => !!value || "Required",
        (value) => value === this.form.newPassword || "Password mismatch",
      ],
    };
  },
  mounted() {
    this.notification = "";
    this.$nextTick(() => {
      this.getProfileData();
    });
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    closeNotify() {
      this.notification = "";
    },
    submitEnable() {
      return (
        this.form.oldPassword?.length > 0 &&
        this.form.newPassword?.length >= 6 &&
        this.form.oldPassword !== this.form.newPassword &&
        this.form.confirmPassword === this.form.newPassword
      );
    },
    getProfileData() {
      this.isUpdating = true;
      awsAuth
        .userData()
        .then((response) => {
          this.form = { ...response.data };
          this.isUpdating = false;
        });
    },
    changePassword() {
      this.isUpdating = true;
      awsAuth
        .editPassword(
          this.form.username,
          this.form.oldPassword,
          this.form.newPassword
        )
        .then((result) => {
          this.notification = `${result.data}, profile updated!`;
          this.isUpdating = false;
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: this.notification,
          });
          this.$router.push("/profile");
          // console.log(`${result.data}, profile updated!`);
        })
        .catch((error) => {
          this.notification = `ERROR, ${error.message}`;
          this.isUpdating = false;
          // console.log(`ERROR, ${error.message}!`);
          this.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    newPasswordInput() {
      if (this.form.confirmPassword) {
        this.$refs.confirmPassword.validate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-field {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(233, 247, 249, 0.6);
  width: 100vw;
  height: calc(100vh - 12vh);
}
.fields-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  background: white;
  width: 30vw;
  // height: 65vh;
  padding: 1vw 1vw 1vw 1vw;
  border: 1px solid #e9eeef;
  border-radius: 4px;
}
.edit-information {
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 100%;
  color: #99b4b9;
  margin-bottom: 4vh;
  h1 {
    font-weight: 600;

    line-height: 200%;
    color: #252733;
  }
}
.profile-password-btn {
  width: 50%;
  height: 8vh !important;
}
.profile-icon {
  margin-right: 0.5vw;
}
.edit-password-profile {
  width: 28vw;
}
@media screen and (max-width: 580px) {
  .wrapper-field {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.6);
    width: 100vw;
    // height: calc(100vh - 70px);
  }
  .fields-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 90vw;
    border: none;
    // padding: 1vw 1vw 1vw 6vw;
  }
  .edit-information {
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 2vh;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 6vh;
    h1 {
      font-weight: 600;
      line-height: 200%;
      color: #252733;
    }
  }
  .profile-password-btn {
    width: 100%;
    height: 8vh !important;
  }
  .profile-icon {
    margin-right: 1vw;
    // margin-top: 5px;
  }
  .edit-password-profile {
    width: 80vw;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .fields-password {
    width: 60vw;
    height: 45vh;
    padding: 0 10vw;
    @media screen and (max-width: 768px) {
      height: 53vh;
    }
  }
  .edit-information {
    font-size: 1.5vh;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 3vh;
  }
  .profile-password-btn {
    width: 50%;
    height: 6vh !important;
  }
  .profile-icon {
    margin-right: 3vw;
    height: 16px;
    @media screen and (max-width: 768px) {
      // margin-top: 7px;
    }
  }
  .edit-password-profile {
    width: 40vw;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 3800px) {
  .fields-password {
    width: 25vw;
    height: 40vh;
    // padding: 1vw 1vw 1vw 4vw;
  }
  .edit-information {
    font-size: 1.5vh;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 4vh;
  }
  .profile-password-btn {
    height: 5vh !important;
    font-size: 24px;
  }
  .profile-icon {
    margin-right: 0.5vw;
    height: 40px !important;
    margin-bottom: 25px;
  }
  .edit-password-profile {
    width: 18vw;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .fields-password {
    width: 48vw;
    height: 60vh;
    padding: 0 10vw;
    @media screen and (max-device-width: 800px) {
      height: 67vh;
    }
  }
}
</style>

<style>
@media screen and (min-width: 3800px) {
  .theme--light.v-label {
    font-size: 25px;
  }
}
</style>
